<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

import Swal from "sweetalert2";
import axios from "axios";

import { useRoute, useRouter } from "vue-router";
import { onMounted } from "vue";
// import moment from 'moment';
import Cookies from "js-cookie";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Detail Agenda Rapat Direksi",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    onMounted(async () => {
      await router.isReady();
    });

    return { route };
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Detail Agenda Rapat Direksi",
      items: [
        {
          text: "Administrasi Rapat",
          href: "/",
        },
        {
          text: "Absensi Rapat Direksi",
          href: "/administrasi/rapat-direksi",
        },
        {
          text: "Detail",
          active: true,
        },
      ],
      row_dokumenn_pertanggung_jawaban: [],
      // variable Page

      seriesDireksi: [],
      seriesSEVP: [],
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: ["Hadir", "Tidak Hadir"],
        colors: ["#0045e6", "#e64500"],
        legend: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "center",
          floating: false,
          fontSize: "14px",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      rapat_direksi_id: this.$route.params.rd_id,
      ar_tanggal: "",
      ar_jumlah: "",
      ar_lokasi: "",
      ar_lokasi_lainnya: "",
      absensi_rapat_agenda: [],
      kehadiran_direksi: [],
      kehadiran_sevp: [],
      ttd_kehadiran_direksi: [],
      ttd_kehadiran_pemateri: [],
      ttd_kehadiran_pendamping: [],
      ttd_kehadiran_sevp: [],
      waktu_rapat: "",
      jumlah_hadir_direksi: "",
      jumlah_tidak_hadir_direksi: "",
      jumlah_hadir_sevp: "",
      jumlah_tidak_hadir_sevp: "",
      loadingTable: true,
      barcode_link: "",
      barcode_link_ori: "",
      barcode_token: "",
      access_token_brigate: Cookies.get("access_token_brigate"),
    };
  },
  mounted() {
    if (localStorage.getItem("reloaded")) {
      localStorage.removeItem("reloaded");
    } else {
      localStorage.setItem("reloaded", "1");
      location.reload();
    }
    this.getRapatDireksi();
  },

  methods: {
    getRapatDireksi() {
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      let self = this;
      self.loadingTable = true;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/administrasirapat/detailabsensirapatdireksi?i=" +
          self.$route.params.rd_id
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            var absensi_rapat = response_data.absensi_rapat;
            self.ar_tanggal = absensi_rapat.ar_tanggal;
            self.ar_jumlah = absensi_rapat.ar_jumlah;
            self.ar_lokasi = absensi_rapat.ar_lokasi;
            self.ar_lokasi_lainnya = absensi_rapat.ar_lokasi_lainnya;
            self.barcode_token = absensi_rapat.barcode_token;

            self.jumlah_hadir_sevp = response_data.jumlah_hadir_sevp;
            self.jumlah_tidak_hadir_sevp =
              response_data.jumlah_tidak_hadir_sevp;
            self.jumlah_hadir_direksi = response_data.jumlah_hadir_direksi;
            self.jumlah_tidak_hadir_direksi =
              response_data.jumlah_tidak_hadir_direksi;

            self.seriesSEVP.push(self.jumlah_hadir_sevp);
            self.seriesSEVP.push(self.jumlah_tidak_hadir_sevp);
            self.seriesDireksi.push(self.jumlah_hadir_direksi);
            self.seriesDireksi.push(self.jumlah_tidak_hadir_direksi);

            self.absensi_rapat_agenda = response_data.absensi_rapat_agenda;
            self.kehadiran_direksi = response_data.kehadiran_direksi;
            self.kehadiran_sevp = response_data.kehadiran_sevp;
            self.ttd_kehadiran_direksi = response_data.ttd_kehadiran_direksi;
            self.ttd_kehadiran_pemateri = response_data.ttd_kehadiran_pemateri;
            self.ttd_kehadiran_pendamping =
              response_data.ttd_kehadiran_pendamping;
            self.ttd_kehadiran_sevp = response_data.ttd_kehadiran_sevp;
            self.waktu_rapat = response_data.waktu_rapat;
            self.loadingTable = false;

            var FormData = require("form-data");
            var data = new FormData();
            var urlres = "administrasi/rapat-direksi/ttd-barcode/" + self.barcode_token;
            data.append("link", urlres);

            var config = {
              method: "post",
              url:
                process.env.VUE_APP_BACKEND_URL_VERSION +
                "api/master/barcodehandle/barcodeadministrasi?token=" +
                self.barcode_token,
              data: data,
            };
            axios(config)
              .then(function (response) {
                var response_data = response.data;
                var barcode_url = process.env.VUE_APP_BACKEND_URL_VERSION;
                barcode_url += response_data.barcode;
                self.barcode_link = barcode_url;
                self.barcode_link_ori = response_data.barcode;

                Swal.close();
              })
              .catch((e) => {
                this.axiosCatchError = e.response.data.data;
                Swal.close();
              });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Gagal Load Data!",
            });
          }
        });
    },

    getDateIndo(input_date) {
      let arrbulan = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      let arrhari = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];
      var date = new Date(input_date);
      var hari = date.getDay();
      var tanggal = date.getDate();
      var bulan = date.getMonth();
      var tahun = date.getFullYear();
      return (
        arrhari[hari] + ", " + tanggal + " " + arrbulan[bulan] + " " + tahun
      );
    },

    addRowDokumenPertanggungJawaban() {
      this.row_dokumenn_pertanggung_jawaban.push({
        dokumen_pertanggung_jawaban_id: "",
        nama_dokumen: "",
        file: "",
      });
    },

    removeRowDokumenPertanggungJawaban(key_deleted) {
      this.row_dokumenn_pertanggung_jawaban.splice(key_deleted, 1);
    },

    pdfDownload() {
      let self = this;
      const link = document.createElement("a");
      link.href =
        process.env.VUE_APP_BACKEND_URL +
        "/administrasi/absensirapat/pdf?i=" +
        self.rapat_direksi_id;
      link.setAttribute("target", "_blank");
      link.click();
    },

    pdfDownloadAbsensiDireksi() {
      let self = this;
      const link = document.createElement("a");
      link.href =
        process.env.VUE_APP_BACKEND_URL +
        "/administrasi/absensirapat/unduh_absensi_direksi?i=" +
        self.rapat_direksi_id;
      link.setAttribute("target", "_blank");
      link.click();
    },

    pdfDownloadAbsensiSEVP() {
      let self = this;
      const link = document.createElement("a");
      link.href =
        process.env.VUE_APP_BACKEND_URL +
        "/administrasi/absensirapat/unduh_absensi_sevp?i=" +
        self.rapat_direksi_id;
      link.setAttribute("target", "_blank");
      link.click();
    },

    pdfDownloadAbsensiDivisiPemateri() {
      let self = this;
      const link = document.createElement("a");
      link.href =
        process.env.VUE_APP_BACKEND_URL +
        "/administrasi/absensirapat/unduh_absensi_pemateri?i=" +
        self.rapat_direksi_id;
      link.setAttribute("target", "_blank");
      link.click();
    },

    pdfDownloadAbsensiDivisiPendamping() {
      let self = this;
      const link = document.createElement("a");
      link.href =
        process.env.VUE_APP_BACKEND_URL +
        "/administrasi/absensirapat/unduh_absensi_pendamping?i=" +
        self.rapat_direksi_id;
      link.setAttribute("target", "_blank");
      link.click();
    },

    pptDownload() {
      let self = this;
      const link = document.createElement("a");
      link.href =
        process.env.VUE_APP_BACKEND_URL +
        "/administrasi/absensirapat/ppt?i=" +
        self.rapat_direksi_id;
      link.setAttribute("target", "_blank");
      link.click();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form class="p-2">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <b-tabs
                    justified
                    nav-class="nav-tabs-custom"
                    content-class="p-3 text-muted"
                  >
                    <b-tab active>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="fas fa-home"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">Detail Rapat</span>
                      </template>
                      <div class="row">
                        <div class="col-md-12 mt-3">
                          <div class="text-end">
                            <div
                              class="btn btn-warning btn-sm m-1 text-black"
                              @click="pdfDownload"
                            >
                              <i class="fa fa-file-pdf"></i> Download PDF
                            </div>
                            <div
                              class="btn btn-danger btn-sm m-1"
                              @click="pptDownload"
                            >
                              <i class="fa fa-file-pdf"></i> Download PPT
                            </div>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <h4>Detail Data Rapat</h4>
                          <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                            <tbody>
                              <tr>
                                <td>Hari,Tanggal Rapat</td>
                                <td>:</td>
                                <td>{{ getDateIndo(ar_tanggal) }}</td>
                              </tr>
                              <tr>
                                <td>Lokasi Rapat</td>
                                <td>:</td>
                                <td>
                                  <span v-if="ar_lokasi_lainnya">
                                    {{ ar_lokasi_lainnya }}
                                  </span>
                                  <span v-else>
                                    {{ ar_lokasi }}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>Jumlah kuorumm</td>
                                <td>:</td>
                                <td>{{ ar_jumlah }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-md-4">
                          <h4>QR Code</h4>
                          <center>
                            <div
                              v-if="barcode_link"
                              style="border: 1px solid #dddd; padding: 8px"
                            >
                              <img
                                v-bind:src="barcode_link"
                                width="250"
                              />
                            </div>
                            <div v-else>Barcode Tidak Tersedia</div>
                          </center>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-md-6">
                          <div class="card">
                            <div class="card-header">
                              <p>Kehadiran Direksi</p>
                            </div>
                            <div class="card-body">
                              <div id="chart">
                                <apexchart
                                  type="pie"
                                  :options="chartOptions"
                                  :series="seriesDireksi"
                                ></apexchart>
                              </div>
                              <br />
                              <table
                                class="table table-bordered"
                                style="width: 100%"
                              >
                                <tbody>
                                  <tr>
                                    <td colspan="2">Keterangan</td>
                                  </tr>
                                  <tr>
                                    <td class="text-center">Direksi</td>
                                    <td class="text-center">
                                      Status Kehadiran
                                    </td>
                                  </tr>
                                  <tr v-if="loadingTable">
                                    <td colspan="2">
                                      <i class="fas fa-spinner fa-spin"></i>
                                      Loading...
                                    </td>
                                  </tr>
                                  <tr v-else-if="kehadiran_direksi == ''">
                                    <td
                                      class="text-center"
                                      colspan="2"
                                    >
                                      Data Tidak Tersedia
                                    </td>
                                  </tr>
                                  <tr
                                    v-for="(
                                      row_data, key_data
                                    ) in kehadiran_direksi"
                                    :key="key_data"
                                  >
                                    <td>{{ row_data.nama_direksi }}</td>
                                    <td class="text-center">
                                      <div v-if="
                                        row_data.status_kehadiran == 'Hadir'
                                      ">
                                        <div
                                          type="button"
                                          class="btn btn-success btn-sm"
                                        >
                                          <i class="bx bx-check-double"></i>
                                          Hadir
                                        </div>
                                      </div>
                                      <div v-else>
                                        <div
                                          type="button"
                                          class="btn btn-danger btn-sm"
                                        >
                                          <i class="fas fa-times"></i> Tidak
                                          Hadir
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="card">
                            <div class="card-header">
                              <p>Kehadiran SEVP</p>
                            </div>
                            <div class="card-body">
                              <div id="chart">
                                <apexchart
                                  type="pie"
                                  :options="chartOptions"
                                  :series="seriesSEVP"
                                ></apexchart>
                              </div>
                              <br />
                              <table
                                class="table table-bordered"
                                style="width: 100%"
                              >
                                <tbody>
                                  <tr>
                                    <td colspan="2">Keterangan</td>
                                  </tr>
                                  <tr>
                                    <td class="text-center">SEVP</td>
                                    <td class="text-center">
                                      Status Kehadiran
                                    </td>
                                  </tr>
                                  <tr v-if="loadingTable">
                                    <td colspan="2">
                                      <i class="fas fa-spinner fa-spin"></i>
                                      Loading...
                                    </td>
                                  </tr>
                                  <tr v-else-if="kehadiran_sevp == ''">
                                    <td
                                      class="text-center"
                                      colspan="2"
                                    >
                                      Data Tidak Tersedia
                                    </td>
                                  </tr>
                                  <tr
                                    v-for="(
                                      row_data, key_data
                                    ) in kehadiran_sevp"
                                    :key="key_data"
                                  >
                                    <td>{{ row_data.nama_sevp }}</td>
                                    <td class="text-center">
                                      <div v-if="
                                        row_data.status_kehadiran == 'Hadir'
                                      ">
                                        <div
                                          type="button"
                                          class="btn btn-success btn-sm"
                                        >
                                          <i class="bx bx-check-double"></i>
                                          Hadir
                                        </div>
                                      </div>
                                      <div v-else>
                                        <div
                                          type="button"
                                          class="btn btn-danger btn-sm"
                                        >
                                          <i class="fas fa-times"></i> Tidak
                                          Hadir
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                          <thead>
                            <tr class="text-center">
                              <th>#</th>
                              <th>Agenda Rapat</th>
                              <th>Jam Mulai</th>
                              <th>Jam Selesai</th>
                              <th>Divisi Pemateri</th>
                              <th>Divisi Pendamping</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="loadingTable">
                              <td colspan="6">
                                <i class="fas fa-spinner fa-spin"></i>
                                Loading...
                              </td>
                            </tr>
                            <tr v-else-if="absensi_rapat_agenda == ''">
                              <td
                                class="text-center"
                                colspan="6"
                              >
                                Data Tidak Tersedia
                              </td>
                            </tr>
                            <tr
                              v-for="(
                                row_data, key_data
                              ) in absensi_rapat_agenda"
                              :key="key_data"
                            >
                              <td>{{ key_data + 1 }}</td>
                              <td>{{ row_data.ara_nama }}</td>
                              <td>{{ row_data.ara_mulai }}</td>
                              <td>{{ row_data.ara_selesai }}</td>
                              <td>
                                <ol v-if="row_data.ara_nama_divisi_materi">
                                  <li
                                    v-for="(item, key_items) in JSON.parse(
                                      row_data.ara_nama_divisi_materi
                                    )"
                                    :key="item"
                                  >
                                    {{ key_items + 1 }}. {{ item }} (
                                    {{
                                      JSON.parse(
                                        row_data.ara_singkatan_divisi_materi
                                      )[key_items]
                                    }}
                                    )
                                  </li>
                                </ol>
                              </td>
                              <td>
                                <ol v-if="row_data.ara_nama_divisi_pendamping">
                                  <li
                                    v-for="(item, key_items) in JSON.parse(
                                      row_data.ara_nama_divisi_pendamping
                                    )"
                                    :key="item"
                                  >
                                    {{ key_items + 1 }}. {{ item }} (
                                    {{
                                      JSON.parse(
                                        row_data.ara_singkatan_divisi_pendamping
                                      )[key_items]
                                    }}
                                    )
                                  </li>
                                </ol>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class="row text-end">
                        <div class="col-md-8"></div>
                        <div class="col-md-4 mt-4">
                          <router-link
                            :to="{ name: 'rapat-direksi' }"
                            class="btn bg-warning btn-sm text-black"
                          >
                            <i class="fa fa-chevron-left"></i> Kembali Ke Rapat
                            Direksi
                          </router-link>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="far fa-user"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">Absensi Direksi</span>
                      </template>
                      <div class="row p-2">
                        <div class="col-md-12">
                          <center>
                            <h4>Absensi Kehadiran</h4>
                          </center>
                        </div>
                        <div class="col-md-12 text-end">
                          <div
                            class="btn btn-warning btn-sm m-1 text-black"
                            @click="pdfDownloadAbsensiDireksi"
                          >
                            <i class="fa fa-file-pdf"></i> Download PDF
                          </div>
                        </div>
                        <div class="col-md-4">
                          <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                            <tbody>
                              <tr>
                                <td>Hari, Tanggal</td>
                                <td>:</td>
                                <td>{{ getDateIndo(ar_tanggal) }}</td>
                              </tr>
                              <tr>
                                <td>Waktu</td>
                                <td>:</td>
                                <td>{{ waktu_rapat }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-md-12 mt-3">
                          <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                            <thead>
                              <tr class="text-center">
                                <th>No</th>
                                <th>Nama</th>
                                <th
                                  colspan="2"
                                  class="text-center"
                                >
                                  Tanda Tangan
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-if="loadingTable">
                                <td colspan="3">
                                  <i class="fas fa-spinner fa-spin"></i>
                                  Loading...
                                </td>
                              </tr>
                              <tr v-else-if="ttd_kehadiran_direksi == ''">
                                <td
                                  class="text-center"
                                  colspan="3"
                                >
                                  Data Tidak Tersedia
                                </td>
                              </tr>
                              <tr
                                v-for="(
                                  row_data, key_data
                                ) in ttd_kehadiran_direksi"
                                :key="key_data"
                              >
                                <td>{{ key_data + 1 }}</td>
                                <td>{{ row_data.nama }}</td>
                                <td>
                                  <div v-if="key_data % 2 == 0">
                                    {{ key_data + 1 }}.
                                    <span v-if="row_data.file_ttd">
                                      <img
                                        alt=""
                                        style="
                                          width: 100px;
                                          height: 100px;
                                          border: 1px solid #7b7b7b;
                                          margin-bottom: 10px;
                                          object-fit: cover;
                                        "
                                        id="img-cv"
                                        v-bind:src="row_data.file_ttd"
                                      />
                                    </span>
                                    <span v-else> - </span>
                                  </div>
                                </td>
                                <td>
                                  <div v-if="key_data % 2 != 0">
                                    {{ key_data + 1 }}.
                                    <span v-if="row_data.file_ttd">
                                      <img
                                        alt=""
                                        style="
                                          width: 100px;
                                          height: 100px;
                                          border: 1px solid #7b7b7b;
                                          margin-bottom: 10px;
                                          object-fit: cover;
                                        "
                                        id="img-cv"
                                        v-bind:src="row_data.file_ttd"
                                      />
                                    </span>
                                    <span v-else> - </span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="row text-end">
                          <div class="col-md-8"></div>
                          <div class="col-md-4 mt-4">
                            <router-link
                              :to="{ name: 'rapat-direksi' }"
                              class="btn bg-warning btn-sm text-black"
                            >
                              <i class="fa fa-chevron-left"></i> Kembali Ke
                              Rapat Direksi
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="far fa-envelope"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">Absensi SEVP</span>
                      </template>
                      <div class="row p-2">
                        <div class="col-md-12">
                          <center>
                            <h4>Absensi Kehadiran SEVP</h4>
                          </center>
                        </div>
                        <div class="col-md-12 text-end">
                          <div
                            class="btn btn-warning btn-sm m-1 text-black"
                            @click="pdfDownloadAbsensiSEVP"
                          >
                            <i class="fa fa-file-pdf"></i> Download PDF
                          </div>
                        </div>
                        <div class="col-md-4">
                          <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                            <tbody>
                              <tr>
                                <td>Hari, Tanggal</td>
                                <td>:</td>
                                <td>{{ getDateIndo(ar_tanggal) }}</td>
                              </tr>
                              <tr>
                                <td>Waktu</td>
                                <td>:</td>
                                <td>{{ waktu_rapat }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-md-12 mt-3">
                          <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                            <thead>
                              <tr class="text-center">
                                <th>No</th>
                                <th>Nama</th>
                                <th
                                  colspan="2"
                                  class="text-center"
                                >
                                  Tanda Tangan
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-if="loadingTable">
                                <td colspan="3">
                                  <i class="fas fa-spinner fa-spin"></i>
                                  Loading...
                                </td>
                              </tr>
                              <tr v-else-if="ttd_kehadiran_sevp == ''">
                                <td
                                  class="text-center"
                                  colspan="3"
                                >
                                  Data Tidak Tersedia
                                </td>
                              </tr>
                              <tr
                                v-for="(
                                  row_data, key_data
                                ) in ttd_kehadiran_sevp"
                                :key="key_data"
                              >
                                <td>{{ key_data + 1 }}</td>
                                <td>{{ row_data.nama }}</td>
                                <td>
                                  <div v-if="key_data % 2 == 0">
                                    {{ key_data + 1 }}.
                                    <span v-if="row_data.file_ttd">
                                      <img
                                        alt=""
                                        style="
                                          width: 100px;
                                          height: 100px;
                                          border: 1px solid #7b7b7b;
                                          margin-bottom: 10px;
                                          object-fit: cover;
                                        "
                                        id="img-cv"
                                        v-bind:src="row_data.file_ttd"
                                      />
                                    </span>
                                    <span v-else> - </span>
                                  </div>
                                </td>
                                <td>
                                  <div v-if="key_data % 2 != 0">
                                    {{ key_data + 1 }}.
                                    <span v-if="row_data.file_ttd">
                                      <img
                                        alt=""
                                        style="
                                          width: 100px;
                                          height: 100px;
                                          border: 1px solid #7b7b7b;
                                          margin-bottom: 10px;
                                          object-fit: cover;
                                        "
                                        id="img-cv"
                                        v-bind:src="row_data.file_ttd"
                                      />
                                    </span>
                                    <span v-else> - </span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="row text-end">
                          <div class="col-md-8"></div>
                          <div class="col-md-4 mt-4">
                            <router-link
                              :to="{ name: 'rapat-direksi' }"
                              class="btn bg-warning btn-sm text-black"
                            >
                              <i class="fa fa-chevron-left"></i> Kembali Ke
                              Rapat Direksi
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="fas fa-cog"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">Absensi Pemateri</span>
                      </template>
                      <div class="row p-2">
                        <div class="col-md-12">
                          <center>
                            <h4>Absensi Kehadiran Divisi Pemateri pada</h4>
                          </center>
                        </div>
                        <div class="col-md-12 text-end">
                          <div
                            class="btn btn-warning btn-sm m-1 text-black"
                            @click="pdfDownloadAbsensiDivisiPemateri"
                          >
                            <i class="fa fa-file-pdf"></i> Download PDF
                          </div>
                        </div>
                        <div class="col-md-4">
                          <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                            <tbody>
                              <tr>
                                <td>Hari, Tanggal</td>
                                <td>:</td>
                                <td>{{ getDateIndo(ar_tanggal) }}</td>
                              </tr>
                              <tr>
                                <td>Waktu</td>
                                <td>:</td>
                                <td>{{ waktu_rapat }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div class="col-md-12 mt-3">
                          <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                            <thead>
                              <tr class="text-center">
                                <th>No</th>
                                <th>Nama Pejabat</th>
                                <th>Nama Divisi</th>
                                <th
                                  colspan="2"
                                  class="text-center"
                                >
                                  Tanda Tangan
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-if="loadingTable">
                                <td colspan="4">
                                  <i class="fas fa-spinner fa-spin"></i>
                                  Loading...
                                </td>
                              </tr>
                              <tr v-else-if="ttd_kehadiran_pemateri == ''">
                                <td
                                  class="text-center"
                                  colspan="4"
                                >
                                  Data Tidak Tersedia
                                </td>
                              </tr>
                              <tr
                                v-for="(
                                  row_data, key_data
                                ) in ttd_kehadiran_pemateri"
                                :key="key_data"
                              >
                                <td>{{ key_data + 1 }}</td>
                                <td>{{ row_data.nama }}</td>
                                <td>{{ row_data.divisi }}</td>
                                <td>
                                  <div v-if="key_data % 2 == 0">
                                    {{ key_data + 1 }}.
                                    <span v-if="row_data.file_ttd">
                                      <img
                                        alt=""
                                        style="
                                          width: 100px;
                                          height: 100px;
                                          border: 1px solid #7b7b7b;
                                          margin-bottom: 10px;
                                          object-fit: cover;
                                        "
                                        id="img-cv"
                                        v-bind:src="row_data.file_ttd"
                                      />
                                    </span>
                                    <span v-else> - </span>
                                  </div>
                                </td>
                                <td>
                                  <div v-if="key_data % 2 != 0">
                                    {{ key_data + 1 }}.
                                    <span v-if="row_data.file_ttd">
                                      <img
                                        alt=""
                                        style="
                                          width: 100px;
                                          height: 100px;
                                          border: 1px solid #7b7b7b;
                                          margin-bottom: 10px;
                                          object-fit: cover;
                                        "
                                        id="img-cv"
                                        v-bind:src="row_data.file_ttd"
                                      />
                                    </span>
                                    <span v-else> - </span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="row text-end">
                          <div class="col-md-8"></div>
                          <div class="col-md-4 mt-4">
                            <router-link
                              :to="{ name: 'rapat-direksi' }"
                              class="btn bg-warning btn-sm text-black"
                            >
                              <i class="fa fa-chevron-left"></i> Kembali Ke
                              Rapat Direksi
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="fas fa-cog"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">Absensi Pendamping</span>
                      </template>
                      <div class="row p-2">
                        <div class="col-md-12">
                          <center>
                            <h4>Absensi Kehadiran Divisi Pendamping pada</h4>
                          </center>
                        </div>
                        <div class="col-md-12 text-end">
                          <div
                            class="btn btn-warning btn-sm m-1 text-black"
                            @click="pdfDownloadAbsensiDivisiPendamping"
                          >
                            <i class="fa fa-file-pdf"></i> Download PDF
                          </div>
                        </div>
                        <div class="col-md-4">
                          <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                            <tbody>
                              <tr>
                                <td>Hari, Tanggal</td>
                                <td>:</td>
                                <td>{{ getDateIndo(ar_tanggal) }}</td>
                              </tr>
                              <tr>
                                <td>Waktu</td>
                                <td>:</td>
                                <td>{{ waktu_rapat }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div class="col-md-12 mt-3">
                          <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                            <thead>
                              <tr class="text-center">
                                <th>No</th>
                                <th>Nama Pejabat</th>
                                <th>Nama Divisi</th>
                                <th
                                  colspan="2"
                                  class="text-center"
                                >
                                  Tanda Tangan
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-if="loadingTable">
                                <td colspan="4">
                                  <i class="fas fa-spinner fa-spin"></i>
                                  Loading...
                                </td>
                              </tr>
                              <tr v-else-if="ttd_kehadiran_pendamping == ''">
                                <td
                                  class="text-center"
                                  colspan="4"
                                >
                                  Data Tidak Tersedia
                                </td>
                              </tr>
                              <tr
                                v-for="(
                                  row_data, key_data
                                ) in ttd_kehadiran_pendamping"
                                :key="key_data"
                              >
                                <td>{{ key_data + 1 }}</td>
                                <td>{{ row_data.nama }}</td>
                                <td>{{ row_data.divisi }}</td>
                                <td>
                                  <div v-if="key_data % 2 == 0">
                                    {{ key_data + 1 }}.
                                    <span v-if="row_data.file_ttd">
                                      <img
                                        alt=""
                                        style="
                                          width: 100px;
                                          height: 100px;
                                          border: 1px solid #7b7b7b;
                                          margin-bottom: 10px;
                                          object-fit: cover;
                                        "
                                        id="img-cv"
                                        v-bind:src="row_data.file_ttd"
                                      />
                                    </span>
                                    <span v-else> - </span>
                                  </div>
                                </td>
                                <td>
                                  <div v-if="key_data % 2 != 0">
                                    {{ key_data + 1 }}.
                                    <span v-if="row_data.file_ttd">
                                      <img
                                        alt=""
                                        style="
                                          width: 100px;
                                          height: 100px;
                                          border: 1px solid #7b7b7b;
                                          margin-bottom: 10px;
                                          object-fit: cover;
                                        "
                                        id="img-cv"
                                        v-bind:src="row_data.file_ttd"
                                      />
                                    </span>
                                    <span v-else> - </span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="row text-end">
                          <div class="col-md-8"></div>
                          <div class="col-md-4 mt-4">
                            <router-link
                              :to="{ name: 'rapat-direksi' }"
                              class="btn bg-warning btn-sm text-black"
                            >
                              <i class="fa fa-chevron-left"></i> Kembali Ke
                              Rapat Direksi
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
